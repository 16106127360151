import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	Checkbox,
	Button,
	PasswordInput,
	Text,
	TextInput,
	Flex,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import AppButton from "../../../components/buttons/AppButton";

export interface IFormState {
	email: string;
	password: string;
}

type Props = {
	state: IFormState;
	loading: boolean;
	handleSubmit: (obj: IFormState) => void;
};

const styles = {
	label: {
		// fontSize: 15,
		fontWeight: 400,
		// marginBottom: 5,
	},
};

function LoginForm({ state, loading, handleSubmit }: Props) {
	const [rememberMeChecked, setRememberMeChecked] = useState(false);

	const toggleRememberMe = () => {
		setRememberMeChecked(!rememberMeChecked);
		localStorage.setItem("persist", JSON.stringify(!rememberMeChecked));
	};

	const form = useForm({
		initialValues: state,
		validate: {
			email: (value) => {
				if (value === "") {
					return "Email required";
				}

				if (!/^\S+@\S+$/.test(value)) {
					return "Invalid email";
				}

				return null;
			},

			// email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
			password: (value) => (value === "" ? "Password required" : null),
		},
	});

	// useEffect(() => {
	// 	localStorage.setItem("persist", JSON.stringify(rememberMeChecked));
	// }, [rememberMeChecked]);

	return (
		<form
			onSubmit={form.onSubmit((values) => handleSubmit(values))}
			className="login__form"
		>
			<div className="input_field-container">
				<TextInput
					value={state.email}
					name="email"
					label="Email"
					placeholder="e.g. mary@business.com"
					{...form.getInputProps("email")}
					styles={styles}
				/>
				<PasswordInput
					label="Password"
					name="password"
					value={state.password}
					placeholder="Password"
					styles={styles}
					{...form.getInputProps("password")}
				/>
			</div>

			<Flex align="end" justify="space-between">
				<Checkbox
					mt="sm"
					label="Remember me"
					checked={rememberMeChecked}
					onChange={toggleRememberMe}
					// {...form.getInputProps("termsOfService", { type: "checkbox" })}
				/>

				<Link to="/forgot-password" className="forgot_password-link">
					Forgot password?
				</Link>
			</Flex>

			<AppButton
				variant="filled"
				loading={loading}
				mt="30"
				fullWidth
				type="submit"
				label="Login"
			/>

			<Text className="signup_link_text">
				Don't have an account? <Link to="/become-a-partner">Sign up</Link>
			</Text>
		</form>
	);
}

export default LoginForm;
