import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Text, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { forgotPassword } from "../../api/api/authApi";
import Copyright from "../../components/Copyright";

const styles = {
	label: {
		fontSize: 24,
		fontWeight: 300,
	},
};

function ForgotPassword() {
	const [state, setState] = useState({ email: "" });

	const form = useForm({
		initialValues: state,
		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
		},
	});

	const handleSubmit = async (value: { email: string }) => {
		const response = await forgotPassword(value.email);
		console.log("Forgot password response", response);
	};

	return (
		<div className="forgot_password">
			<header className="header">
				<Link className="logo" to="/">
					<Title order={3}>Flavor Feed</Title>
				</Link>
			</header>
			<div className="content">
				<div className="text_container">
					<Title order={1} className="title">
						Forgot Password?
					</Title>
					<Text className="helper-text">
						Enter the email associated with your account
					</Text>
				</div>

				<form
					onSubmit={form.onSubmit((value) => handleSubmit(value))}
					className="form"
				>
					<TextInput
						value={state.email}
						name="email"
						label="Email Address"
						placeholder="e.g.john@business.com"
						{...form.getInputProps("email")}
						styles={styles}
					/>

					<Button bg="#212631" fullWidth className="button" type="submit">
						RESET PASSWORD
					</Button>

					<Link to="/login" className="back_button">
						Back to login
					</Link>
				</form>
			</div>

			<div className="copyright_wrapper">
				<Copyright />
			</div>
		</div>
	);
}

export default ForgotPassword;
